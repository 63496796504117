import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today we’re excited to announce the release of our new Auto Router! The Auto Router uses an advanced smart order routing algorithm to find better prices for traders in the `}<a parentName="p" {...{
        "href": "http://app.starswap.xyz"
      }}>{`Starswap app`}</a>{`. This includes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Split routes`}</strong>{` which execute trades across multiple pools at once`}</li>
      <li parentName="ul"><strong parentName="li">{`More powerful algorithm `}</strong>{`that considers a larger data set for larger trades and better prices`}</li>
      <li parentName="ul"><strong parentName="li">{`Gas cost awareness `}</strong>{`ensures every added step is net positive for your trade`}</li>
    </ul>
    <p>{`Lastly, if there is a better trade available on Starswap v2 after accounting for gas costs, the Auto Router will automatically switch to v2.`}</p>
    <h1 {...{
      "id": "split-routes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#split-routes",
        "aria-label": "split routes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Split Routes`}</h1>
    <p>{`One way that the Auto Router achieves better prices is by splitting trades across multiple pools. In the legacy router, a trade was always executed through a single route. Now, your trade can be split across up to seven paths to take advantage of liquidity depth in different pools. In the example below, the trader saves `}{`$`}{`134,689 by splitting the route on a COMP:USDC trade. This is more than enough to cover the additional gas cost of the second route.`}</p>
    <p>{`The addition of multiple fee tiers in Starswap v3 makes this feature more important than ever.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf5e340989a5b2696269b7a6faebcda1/c9d77/split_routes.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABhklEQVQoz12SyW4bMRBE9f+/l4MRxJtGy3Absps7R0YxIC3Hig8P5Omhq7oPgm+QA79/EhpkqJCxQaUGGQtESFApQ6U00TlCRoYIdqJrhC4MXQmHIRQPQuEr1BAah7PUsCEi7TcoZ6HJwXiGKQnvZsOz1DgaA7X9hi70KZT88W9CQRUrFaw+Q0TqvNm++dh9SF0b7sa47kLtOnucjMdytF1eXd9ITdmQHo4mYg0NwjdcOD9EzthGVJ+gOEBxnK+OCSpH2BzgE4MjwWT/HfkXGzxli9fC+FPcjPwtHf8y5SoXqJynTKcIlT1EIcgRtaWHDmnHShVXqri4AnWf1oQPqFgQWkNu++zQkIP2NJfy5hyercUbM1b5BJ3Hcvjeob9NrtRw5YqFEk7Zd7LU27535UI3G/UYSl9pRA54UR5nufWLMl04AfPV4c8tS99w5oIXZihDWDbGoiwW6XDSG84UYHKcuMKgQjDV/3828odwxNajx1ihU4HOg3wnQs0OA2QOs8spuwv/AozKrI2vrbxNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "split routes",
            "title": "split routes",
            "src": "/static/cf5e340989a5b2696269b7a6faebcda1/c1b63/split_routes.png",
            "srcSet": ["/static/cf5e340989a5b2696269b7a6faebcda1/5a46d/split_routes.png 300w", "/static/cf5e340989a5b2696269b7a6faebcda1/0a47e/split_routes.png 600w", "/static/cf5e340989a5b2696269b7a6faebcda1/c1b63/split_routes.png 1200w", "/static/cf5e340989a5b2696269b7a6faebcda1/d61c2/split_routes.png 1800w", "/static/cf5e340989a5b2696269b7a6faebcda1/c9d77/split_routes.png 1964w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`The Auto Router optimizes your price by splitting the route across multiple pools.`}</em></p>
    <h1 {...{
      "id": "more-powerful-algorithm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#more-powerful-algorithm",
        "aria-label": "more powerful algorithm permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`More powerful algorithm`}</h1>
    <p>{`The Auto Router enables efficient execution, especially on large trades. At the time of writing this, without the Auto Router an 80M USDT:USDC swap would incur a price impact of -62.9%. With the Auto Router, the same trade can be executed with a price impact as low as -0.749%.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a30278a14aab22d601c7129da837cff3/c9d77/explore.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABl0lEQVQoz22SyW4cMQxE+/8/0Jd2nNk82heKWrozk5Ih9cBJgBwKugjFV0Uukh5QQ+nXId6heIPOO0zZoXKD5AJdG0yt0LXA1AydCSr7KdsiTAswW8QyDOVfhjLt0LzB+AihLSgXbM8nbAzwRPCcYFvBD2XxdtVYpcfVOdjwDrMRFkXPF+EDgjaI2CCoQEXfg3PdRu6UuGtL3djYPTfokuYfrbg7GeEM99DypFwuhieVHGZUvyPLVGBSgaQMGRNUKlBpRD0i28rwOcKxh8seZsQekd+0wHtQOEWLM1mo1KDSPuOL2WWDLQ2Kx2CCzgm6ZOiaoBpNmS29FLGIuEPEberm2yQcxD4/4HPtT/zuddu7877nnLtLBNcKPnzA6hzOiSCChnIrzM6vDtPR4Z123GnDKTBWK+C069bbvp7O/XK59bsK/eTqpPtpM9ZP7h8i9bOibov7/5YH4WesuAaCj4ybCZDW424ibspBjB4rw1SGqwxbBzHBzOgvw3/ucPTHx+kobjBlbHW85bjDwtD1MB2kug39MfwC1Kurs+SY1ekAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "explore",
            "title": "explore",
            "src": "/static/a30278a14aab22d601c7129da837cff3/c1b63/explore.png",
            "srcSet": ["/static/a30278a14aab22d601c7129da837cff3/5a46d/explore.png 300w", "/static/a30278a14aab22d601c7129da837cff3/0a47e/explore.png 600w", "/static/a30278a14aab22d601c7129da837cff3/c1b63/explore.png 1200w", "/static/a30278a14aab22d601c7129da837cff3/d61c2/explore.png 1800w", "/static/a30278a14aab22d601c7129da837cff3/c9d77/explore.png 1964w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`The Auto Router explores liquidity at greater depth than the standard router which previously ran out of gas.`}</em></p>
    <h1 {...{
      "id": "gas-cost-awareness",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#gas-cost-awareness",
        "aria-label": "gas cost awareness permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Gas Cost Awareness`}</h1>
    <p>{`The Auto Router factors in gas costs to optimize your effective price. With today’s launch, smaller trades will execute more efficiently by minimizing the number of gas-intensive hops to reach the output token. For example, this ETH:LINK trade saves `}{`$`}{`8 in gas fees to deliver a 10% better net price by removing a hop from the route.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/45709bd13f7c7c0b5136c87498943227/c9d77/gas.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABl0lEQVQoz1WS24rcMBBE5/+/L0+BsCQ7Y+tiXVrdutmmtFieTchDoQfBQadUD0UHrui03+EOzQ1aOkzu0FKxcoYqFaoU6JJhi2DliBcHLBKhC8E2hm0Rjxt2QXeo1GcMNyjn8dQWPgnqcSI7h+I9EkWYItAUYKyCWZ9g2eDTL9hKeGg6J2yN7Q5VKKrwoYzo/XDEgygNCmGkLYyc+9BC8KWDDI/0sY7o8vA5wNaIx6fJUHGHipfqWzk1bKnBcYZJAhMTDAtMYmy5TGUtDCUZuhQoYZiSbuXfa8Vidih3qd7Ks8PvSIWSAnOB8nXKDcwEVS79CNsSbOf7hZoOLKFjjR0m7X+hlg8YqUito53nLF7lAFNoQlchfPKGDzZw2cK6n7Dt3eFKB56h4xUbFqp4hoot9uF8GMd5Dh/rsCGMuu9j4zxhljv+BBk/rB5LlOHEw7Tw75evySi6XtdmlliwRIFKGa/IWClBi0Bxes+G592WMxTzNNi+Z/PfDmeHHUbaOxUm19mhydcG87vHq7MEU2nOZWs0P+ULS/ut1kB4ykoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gas",
            "title": "gas",
            "src": "/static/45709bd13f7c7c0b5136c87498943227/c1b63/gas.png",
            "srcSet": ["/static/45709bd13f7c7c0b5136c87498943227/5a46d/gas.png 300w", "/static/45709bd13f7c7c0b5136c87498943227/0a47e/gas.png 600w", "/static/45709bd13f7c7c0b5136c87498943227/c1b63/gas.png 1200w", "/static/45709bd13f7c7c0b5136c87498943227/d61c2/gas.png 1800w", "/static/45709bd13f7c7c0b5136c87498943227/c9d77/gas.png 1964w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`The Auto Router is aware of gas costs and will only take an extra hop if it yields a better effective price.`}</em></p>
    <h1 {...{
      "id": "auto-router-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#auto-router-interface",
        "aria-label": "auto router interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Auto Router Interface`}</h1>
    <p>{`The Auto Router is now live and enabled by default for all users in the Starswap App. You can hover over the Auto Router icon to peak into your trade route.`}</p>
    <p><img parentName="p" {...{
        "src": "/4dfeaac79f091e31ccac8f954312e1ed/demo.gif",
        "alt": null
      }}></img></p>
    <p>{`You may disable the Auto Router in settings if you do not wish to use the Auto Router for any reason.`}</p>
    <h1 {...{
      "id": "open-source-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#open-source-code",
        "aria-label": "open source code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open Source Code`}</h1>
    <p>{`We are deeply committed to decentralized and open-source financial infrastructure. In that spirit, we have published the full Auto Router source code under the GPL license.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/routing-api"
        }}>{`@Starswap/routing-api`}</a>{`: Wraps and deploys the smart-order-router as a hosted service.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/smart-order-router"
        }}>{`@Starswap/smart-order-router`}</a>{`: Contains the logic for finding the most efficient way to swap token A for token B, considering split swaps across multiple routes and gas costs.`}</li>
    </ul>
    <h1 {...{
      "id": "developers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developers",
        "aria-label": "developers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developers`}</h1>
    <p>{`The Starswap App fetches quotes from an instance of the Auto Router hosted by Starswap Labs. Local developers and IPFS builds will be able to call the Auto Router API up to a modest request limit. The app will fallback to legacy routing when the request limit is exceeded, and in any situation where the Auto Router fails to return a quote. If your app needs to handle a higher load, you are welcome to deploy your own instance of the Auto Router from the open source code.`}</p>
    <p>{`We will host a Twitch livestream over the coming weeks to walk through the Auto Router codebase and teach developers how to work with it.`}</p>
    <h1 {...{
      "id": "measuring-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#measuring-improvements",
        "aria-label": "measuring improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Measuring Improvements`}</h1>
    <p>{`In our analysis of 4,429 on-chain swaps from June 1, the Auto Router improves pricing on 13.97% of all trades and 36.84% of trades between the Top 10 tokens by TVL.`}</p>
    <p>{`Benefits are spread across all trade size buckets. Many low value trades benefit from the Auto Router’s awareness of gas costs, allowing it to recommend fewer hops for better overall execution. For larger trades, price improvements are primarily driven by the Auto Router’s new route splitting capabilities and ability to explore more liquidity.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1033px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/93a72d9c90f04a7b9ea6466e0cb36907/42de8/performance.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGklEQVQoz1WTy2sTURxG88cLPrpoFaELFTd1IQUXdqFQQcQiXVVsrekjSfOazDOTO3Pfk3iUO5OCLs72zPf97je9pNqQ1ustDan0zEvPNHfMlo5pYVtmS9NRaqaFYlEpciPJTU1uazJTkVtBLxEbonJNUgUa0tozzjw3c89w4biNHIPIMlgEDMPYcBNpJoUiM5JMB2GF+C22wup/YVJ5MuXJtSPXltxYCmM6rKawqiW3QSbbdJGo+fRNtyl70XLDvFgTi6YT1n6L22JJakMiDanULYlUpKoTlk3NMKl5sNMQS0Fvkm6IxTZh3VVukW6LJQ0y1QkzFWSqlcW1JNU1d1nNk6eeRAl602zDrFizWHWVY+Hb2iFdXHXCkDBIS6/JdEAh/0hOzjRv31vmouLRbhBWnTBerSl0077y0noKE+7oWFpLpiylN+TGcDkyREIzznSb8POp4eWBY1H/I4xXG6Llmv64u+P3fsPlyDMrHJejbjbHXz250ew8a+hPNO8+OI5PDKc/NK/eOCJR8XC36Srf7/D5i4ariefgsOHo2HPWd+y/9tyltvu61Ozte66mmsMjy8cvhtPzILSt8PGeI74Xhkc5vw1jbrieunZ/k9zRHxuileVioFkIw8+hYl4qrmeSQSwZpZJfk5q5qLkYVV3lNmEVbrgmlQ25bsjV/Q272SyEZVYaMmXa6u0WnaSwktJ3f8pqXZE7wV/RwzN2aBruXAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "performance",
            "title": "performance",
            "src": "/static/93a72d9c90f04a7b9ea6466e0cb36907/42de8/performance.png",
            "srcSet": ["/static/93a72d9c90f04a7b9ea6466e0cb36907/5a46d/performance.png 300w", "/static/93a72d9c90f04a7b9ea6466e0cb36907/0a47e/performance.png 600w", "/static/93a72d9c90f04a7b9ea6466e0cb36907/42de8/performance.png 1033w"],
            "sizes": "(max-width: 1033px) 100vw, 1033px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`The Auto Router improves trades of all sizes `}</em></p>
    <h1 {...{
      "id": "future-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#future-work",
        "aria-label": "future work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Future work`}</h1>
    <p>{`Our journey has only just begun! We will continue iteratively improving the Auto Router to unlock better trade prices for users of the Starswap App. The next release will support splitting routes across Starswap v2 and v3 simultaneously and we also plan to support routes on Optimistic Ethereum and Arbitrum.`}</p>
    <p>{`Additionally, we intend to further decentralize the Auto Router for the Starswap Community by publishing a client-side version of the Auto Router.`}</p>
    <p>{`For now, enjoy improved prices in the Starswap App!`}</p>
    <hr></hr>
    <p>{`To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul">{`Join the Starswap community `}<a parentName="li" {...{
          "href": "https://discord.com/invite/FCfyBSbCU5"
        }}>{`discord`}</a></li>
      <li parentName="ul">{`Follow Starswap Labs on `}<a parentName="li" {...{
          "href": "/Starswap"
        }}>{`Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the Starswap Labs `}<a parentName="li" {...{
          "href": "https://starswap.xyz/rss.xml"
        }}>{`blog`}</a></li>
      <li parentName="ul">{`Register as a delegate on `}<a parentName="li" {...{
          "href": "https://sybil.org/#/delegates/Starswap"
        }}>{`Sybil`}</a></li>
      <li parentName="ul">{`Participate in Starswap `}<a parentName="li" {...{
          "href": "https://gov.starswap.xyz/"
        }}>{`governance`}</a></li>
    </ul>
    <p>{`Starswap Team 🦄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      